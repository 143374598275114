import { defineMessages } from 'react-intl';

import AllButTitleBlocks from './Views/AllButTitleBlocks';
import AllButTitleBlocksReadmore from './Views/AllButTitleBlocksReadmore';
import HistoryWidget from './widgets/HistoryWidget/HistoryWidget';
import ListingPersonenUndFunktionen from './blocks/Listing/ListingPersonenUndFunktionen';
import ListingExpandable from './blocks/Listing/ListingExpandable';
import ListingTOC from './blocks/Listing/ListingTOC/ListingTOC';
import ListingManualInformationstyp from './blocks/Listing/ListingManualInformationstyp';
import ListingManualKomponenten from './blocks/Listing/ListingManualKomponenten';
import ManualView from './Views/ManualView';
import MetaInfoToolbar from './MetaInfoToolbar/MetaInfoToolbar';
import OverviewPageSearchView from './Views/OverviewPageSearchView';
import PersonView from './Views/PersonView';
import SharingGroups from './SharingGroups/SharingGroups';
import SharingView from './Views/SharingView';
import VocabularyDictionaryWidget from './widgets/Temp/VocabularyDictionaryWidget';
import DefaultListingBlockTemplatePlus from './blocks/Listing/DefaultListingBlockTemplatePlus';
import UserConfiguration from './Views/UserConfiguration';
import DocumentationSidebar from './DocumentationSidebar/DocumentationSidebar';
import FooComponent from './FooComponent';
import Shortcuts from './Shortcuts';
import EditBookmarks from './EditBookmarks';

const messages = defineMessages({
  // Remove when https://github.com/plone/volto/pull/3427 is released
  general: {
    id: 'General',
    defaultMessage: 'General',
  },
  addonconfiguration: {
    id: 'Add-on Configuration',
    defaultMessage: 'Add-on Configuration',
  },
  content: {
    id: 'Content',
    defaultMessage: 'Content',
  },

  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  Impressum: {
    id: 'Impressum',
    defaultMessage: 'Impressum',
  },
  Website: {
    id: 'Website',
    defaultMessage: 'Website',
  },
  Intern: {
    id: 'Intern',
    defaultMessage: 'Intern',
  },
  Dokumentation: {
    id: 'Dokumentation',
    defaultMessage: 'Dokumentation',
  },
  Lageplan: {
    id: 'Lageplan',
    defaultMessage: 'Lageplan',
  },
  personalTools: {
    id: 'personalTools',
    defaultMessage: 'personalTools',
  },
  editSubscriptions: {
    id: 'Edit Subscriptions',
    defaultMessage: 'Edit Subscriptions',
  },
  userConfiguration: {
    id: 'userConfiguration',
    defaultMessage: 'Configuration',
  },

  // Login and reset password
  'we can send you a new one': {
    id: 'we can send you a new one',
    defaultMessage: 'Reset password',
  },

  // workflow
  // obsolete. see getWorkflowMapping
  show_internally: {
    id: 'show_internally',
    defaultMessage: 'internally visible',
  },
  internally_published: {
    id: 'internally_published',
    defaultMessage: 'internally visible',
  },
  hide: {
    id: 'hide',
    defaultMessage: 'private',
  },
  Private: {
    id: 'Private',
    defaultMessage: 'private',
  },
  private: {
    id: 'private',
    defaultMessage: 'private',
  },
  Public: {
    id: 'Public',
    defaultMessage: 'public',
  },
  published: {
    id: 'published',
    defaultMessage: 'published',
  },
  internal: {
    id: 'internal',
    defaultMessage: 'internal',
  },
  open_for_sharing: {
    id: 'open_for_sharing',
    defaultMessage: 'internal',
  },

  // Actions box
  actionsbox_make_private: {
    id: 'Make private',
    defaultMessage: 'Make private',
  },
  actionsbox_open_for_local_sharing: {
    id: 'Open for local sharing',
    defaultMessage: 'Open for local sharing',
  },
  actionsbox_show_internally: {
    id: 'Show internally',
    defaultMessage: 'Show internally',
  },
  actionsbox_publish: {
    id: 'Publish',
    defaultMessage: 'Publish',
  },

  // Edit.jsx
  saveandstayonpage: {
    id: 'SaveAndStayOnPage',
    defaultMessage: 'Save and stay on page',
  },

  // SearchWidget
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
  resultsFound: {
    id: 'results found',
    defaultMessage: 'Suchergebnisse',
  },
  resultFound: {
    id: 'result found',
    defaultMessage: 'Suchergebnis',
  },
  searchresultsincontext: {
    id: 'Search results for {term} in {searchcontext}',
    defaultMessage: 'Search results for {term} in {searchcontext}',
  },
  // dropdown menu
  closeMenu: {
    id: 'dropdownmenu-close-menu-button',
    defaultMessage: 'Close menu',
  },
  // Login
  registerInfo: {
    id: 'Register Info',
    defaultMessage: 'You can register. Contact the support.',
  },
  // Reset Password
  description_reset_password: {
    id: 'description_reset_password',
    defaultMessage:
      'For security reasons, we store your password encrypted, and cannot mail it to you. If you would like to reset your password, fill out the form below and we will send you an email at the address you gave when you registered to start the process of resetting your password.',
  },
  requestEmailDescription: {
    id: 'requestEmailDescription',
    defineMessages:
      'Your are already registered and forgot your email or your email changed. Please contact the <a hreg="/contact-form">Support</a>',
  },
  // Sidebar
  settings: {
    id: 'Settings',
    defaultMessage: 'Settings',
  },
  // Control panel
  debugmodewarning: {
    id: 'Warning Regarding debug mode',
    defaultMessage: 'Warning Regarding debug mode',
  },
  relatedContent: {
    id: 'relatedContent',
    defaultMessage: 'Maybe also interesting',
  },
  // Slate text block
  shortcutlisting_mod_key: {
    id: "On Windows, the MOD key is Ctrl, on Mac OS X it's Cmd.",
    defaultMessage: "On Windows, the MOD key is Ctrl, on Mac OS X it's Cmd.",
  },
  block_type_chooser: {
    id: 'Type a slash (/) to select a block type.',
    defaultMessage: 'Type a slash (/) to select a block type.',
  },
});

export {
  AllButTitleBlocks,
  AllButTitleBlocksReadmore,
  HistoryWidget,
  ListingPersonenUndFunktionen,
  ListingExpandable,
  ListingTOC,
  ListingManualInformationstyp,
  ListingManualKomponenten,
  ManualView,
  MetaInfoToolbar,
  OverviewPageSearchView,
  PersonView,
  SharingGroups,
  SharingView,
  VocabularyDictionaryWidget,
  DefaultListingBlockTemplatePlus,
  UserConfiguration,
  DocumentationSidebar,
  FooComponent,
  Shortcuts,
  EditBookmarks,
};
