/**
 * Footer component.
 * @module components/theme/Footer/Footer
 * changes:
 * - Links auf Impressum, Login, Logout, Lageplan
 * - Adresse, Kontakt
 * - Settings: Sidebar (glossarytooltips toggeln, etc.)
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Grid, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  personalTools: {
    id: 'Personal tools',
    defaultMessage: 'Einstellungen',
  },
  editSubscriptions: {
    id: 'Edit Subscriptions',
    defaultMessage: 'Edit Subscriptions',
  },
  userConfiguration: {
    id: 'userConfiguration',
    defaultMessage: 'Configuration',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const token = useSelector((state) => state.userSession?.token);
  const currentuser = useSelector((state) => state.users?.user);

  return (
    <Segment role="contentinfo" id="footer">
      <Container className="contentinfo-container">
        <Segment className="footer-hotlinks" basic inverted color="grey">
          <Grid stackable>
            <Grid.Column width={12}>
              <List horizontal className="footer_main_links">
                {/* wrap in div for a11y reasons: listitem role cannot be on the <a> element directly */}
                <List.Item>
                  <Link className="item" to="/impressum">
                    <FormattedMessage
                      id="Impressum"
                      defaultMessage="Impressum"
                    />
                  </Link>
                </List.Item>
                <List.Item>
                  <Link className="item" to="/lageplan">
                    <FormattedMessage id="Lageplan" defaultMessage="Lageplan" />
                  </Link>
                </List.Item>
                {token ? (
                  <List.Item>
                    <Link
                      to="/user-configuration"
                      className="item"
                      aria-label={intl.formatMessage(
                        messages.userConfiguration,
                      )}
                    >
                      <FormattedMessage
                        id="userConfiguration"
                        defaultMessage="Configuration"
                      />
                    </Link>
                  </List.Item>
                ) : (
                  <></>
                )}
                {token ? (
                  <List.Item>
                    <Link
                      className="item"
                      to="/logout"
                      title={'logout ' + currentuser?.fullname ?? 'Admin'}
                    >
                      <FormattedMessage id="Logout" defaultMessage="Logout" />
                    </Link>
                  </List.Item>
                ) : (
                  <List.Item>
                    <Link className="item" to="/login">
                      <FormattedMessage id="Login" defaultMessage="Login" />
                    </Link>
                  </List.Item>
                )}
              </List>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment basic inverted color="grey">
          <Grid stackable>
            <Grid.Column width={4}>
              Geschäftsstelle IGIB/GRIF
              <br />
              Ausstellungsstrasse 80
              <br />
              8090 Zürich
            </Grid.Column>
            <Grid.Column width={4}>
              <List inverted color="grey" className="tabbedList">
                <List.Item>
                  <List.Content>
                    <List.Header>Tel.</List.Header>
                    <List.Description>043 259 78 28</List.Description>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>E-Mail</List.Header>
                    <List.Description>
                      <Link to="/contact-form">Kontakt</Link>
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>&nbsp;</Grid.Column>
          </Grid>
        </Segment>
      </Container>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
